import { CategoryUtils } from '../utils/category.utils';
import { AppPlatform } from './platform';

export class CategoryTranslation {
    id: number;
    name: string;
    locale: string;

    constructor(data?: any) {
        this.id = data?.id;
        this.name = data?.name;
        this.locale = data?.locale;
    }
}

export type CategoryMediaTag = 'vehicles' | 'real_estate';
export type CategoryProperty = 'offerPictureRatio' | 'offerSize' | 'mediaTag' | 'shippable';

export class Category {
    id: number;
    name: string;
    parent: number;
    children: Category[] = [];
    translations: CategoryTranslation[] = [];
    isPromoted: boolean = false;
    shippable: boolean;
    mediaTag: CategoryMediaTag;
    offerCountMarketplace: number = 0;
    offerCountWholesale: number = 0;
    offerCountGazette: number = 0;
    isEnabled: boolean;
    offerSize: string;
    offerPictureRatio: string;
    tags: string[];
    isMarketplace: boolean;
    isWholesale: boolean;
    isGazette: boolean;
    keywords: string[];

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
            this.children = data?.children?.map((child: any) => new Category(child)) || [];
            this.translations = data?.translations?.map((t: any) => new CategoryTranslation(t)) || [];
            this.tags = data?.tags || [];
            this.keywords = data?.keywords || [];
        }
    }

    getPropertyFromRoot(property: CategoryProperty): any {
        return CategoryUtils.findProperty(this, property);
    }

    static toArray(categories: Category[]): Category[] {
        const tree: { [key: number]: Category } = {};
        for (const category of categories) {
            tree[category.id] = new Category(category);
        }

        // Add children to their parents
        for (const category of Object.values(tree)) {
            if (category.parent) {
                if (!tree[category.parent]) {
                    delete tree[category.id];
                    continue;
                }
                tree[category.parent].children.push(category);
            }
        }

        // Remove non-root properties
        return Object.values(tree).filter((c) => !c.parent);
    }

    isPlatform(platform: AppPlatform) {
        switch (platform) {
            case 'marketplace':
                return this.isMarketplace;
            case 'wholesale':
                return this.isWholesale;
            case 'gazette':
                return this.isGazette;
        }
    }

    hasOffersOnPlatform(platform: AppPlatform): boolean {
        switch (platform) {
            case 'marketplace':
                return this.offerCountMarketplace > 0;
            case 'wholesale':
                return this.offerCountWholesale > 0;
            case 'gazette':
                return this.offerCountGazette > 0;
        }
    }
}
